import React, { useState } from 'react';
import axios from 'axios';

import Layout from '../../components/Layout';
import Personal from '../../components/Personal';
import Vehicle from '../../components/Vehicle';
import Driver from '../../components/Driver';
import Coverage from '../../components/Coverage';

const API_URL = process.env.GATSBY_API_URL || 'http://localhost:9000';

const Motorcycle = () => {
  const [info, setInfo] = useState({ vehicles: [] });
  const [personalInfo, setPersonalInfo] = useState({});
  const [vehicleInfo, setVehicleInfo] = useState({});
  const [driverInfo, setDriverInfo] = useState({});
  const [coverageInfo, setCoverageInfo] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async () => {
    try {
      await axios.post(`${API_URL}/quote`, {
        personalInfo,
        info,
        coverageInfo,
      });
      setSubmitted(true);
    } catch (err) {
      console.log(err);
    }
  };

  const onAddVehicle = () => {
    setInfo({ ...info, vehicles: [...info.vehicles, vehicleInfo] });
  };

  const VehicleInfo = ({ vehicle }) => {
    return (
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <p style={{ marginRight: 10 }}>
          <b>Year:</b> {vehicle.year}
        </p>
        <p style={{ marginRight: 10 }}>
          <b>Make:</b> {vehicle.make}
        </p>
        <p style={{ marginRight: 10 }}>
          <b>Model:</b> {vehicle.model}
        </p>
      </div>
    );
  };

  const renderVehicles = () => {
    if (info.vehicles.length < 1) return null;
    return info.vehicles.map((v) => <VehicleInfo vehicle={v} />);
  };
  return (
    <Layout fullMenu>
      <article id="main">
        <header style={{ height: 300 }}>
          <h2>Boat Insurance</h2>
        </header>
        {submitted ? (
          <section className="wrapper style5">
            <h3 style={{ textAlign: 'center' }}>
              Thanks for submitting your info - Agency Insurance will be in
              contact with you shortly!
            </h3>
          </section>
        ) : (
          <section className="wrapper style5">
            <div
              className="inner"
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
              }}
            >
              <h3 style={{ width: '100%', marginBottom: 0 }}>Personal Info</h3>
              <Personal onChange={setPersonalInfo} />
              <h3 style={{ width: '100%', marginBottom: 0 }}>Boat Info</h3>
              {renderVehicles()}
              <Vehicle
                onChange={setVehicleInfo}
                onAddVehicle={onAddVehicle}
                type={'Boat'}
              />
              <h3 style={{ width: '100%', marginBottom: 0 }}>Coverage Info</h3>
              <Coverage onChange={setCoverageInfo} />
            </div>
            <div className="inner">
              <button onClick={onSubmit}>Submit</button>
            </div>
          </section>
        )}
      </article>
    </Layout>
  );
};

export default Motorcycle;
